import axios from "axios";
import { ResultEnum } from "@/api/httpEnum.js";

import router from "../router";

// import router from "@/routers";
import { checkStatus } from "./helper/index.js";
let backendUrl =
  window.location.protocol + "//" + window.location.hostname + "/api";
if (process.env.NODE_ENV === "development") {
  backendUrl = "http://localhost:3000/api/";
}

// backendUrl = "http://139.9.33.196:6100/"; // test
backendUrl = "https://api.360xlink.com/"; // prod
// backendUrl = "https://79d08z7410.goho.co/"; // Zeng local
const config = {
  baseURL: backendUrl,
  timeout: ResultEnum.TIMEOUT,
};
class RequestHttp {
  constructor(config) {
    this.service = axios.create(config);
    this.service.defaults.validateStatus = function (status) {
      return status < 500;
    };
    /**
     * @description 响应拦截器
     *  服务器换返回信息 -> [拦截统一处理] -> 客户端JS获取到信息
     */
    this.service.interceptors.response.use(
      (response) => {
        const { data } = response;
        // 全局错误信息拦截（防止下载文件的时候返回数据流，没有 code 直接报错）
        if (data.code && data.code !== ResultEnum.SUCCESS) {
          if (response.data.code === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            console.log("401");
            router.push("/login");
          }
          // console.error(data.message);
          return Promise.reject(data);
        }
        // 成功请求（在页面上除非特殊情况，否则不用处理失败逻辑）
        return data;
      },
      async (error) => {
        const { response } = error;
        // 请求超时 && 网络错误单独判断，没有 response
        if (error.message.indexOf("timeout") !== -1)
          console.error("请求超时！请您稍后重试");
        if (error.message.indexOf("Network Error") !== -1)
          console.error("网络错误！请您稍后重试");
        // 根据服务器响应的错误状态码，做不同的处理
        if (response) checkStatus(response.status);
        // 服务器结果都没有返回(可能服务器错误可能客户端断网)，断网处理:可以跳转到断网页面
        // if (!window.navigator.onLine) router.replace("/500");
        return Promise.reject(error);
      }
    );

    /**
     * @description 请求拦截器
     * 客户端发送请求 -> [请求拦截器] -> 服务器
     */
    // 设置请求拦截器
    this.service.interceptors.request.use(
      (config) => {
        // 获取 token 并设置为 axios 的 header
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authentication = token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
  /**
   * @description 常用请求方法封装
   */
  get(url, params, _object = {}) {
    return this.service.get(url, { params, ..._object });
  }
  post(url, params, _object = {}) {
    return this.service.post(url, params, _object);
  }
  put(url, params, _object = {}) {
    return this.service.put(url, params, _object);
  }
  patch(url, params, _object = {}) {
    return this.service.patch(url, params, _object);
  }
  delete(url, params, _object = {}) {
    return this.service.delete(url, { params, ..._object });
  }
  download(url, params, _object = {}) {
    return this.service.post(url, params, { ..._object, responseType: "blob" });
  }
}

export default new RequestHttp(config);
